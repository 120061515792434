import React from "react"
import { Container } from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Decoration from "../components/Decoration"

const TermsOfService = () => (
    <Layout id="pages-dark">
        <SEO title="Terms of service" description="Terms of service that is applied to you when playing on the Slashdiablo server" />
        <Header />
        <Container text>
            <Decoration content="Terms of service" />
            <p>
                Empty for now :(
            </p>
        </Container>
    </Layout>
)

export default TermsOfService
